<template>
  <q-card class="bg-white text-dark">
    <q-card-section>
      <q-item>
        <template v-if="imageType">
          <q-item-section v-if="imageType === 'avatar'" avatar>
            <q-avatar>
              <img :src="icon">
            </q-avatar>
          </q-item-section>
          <q-item-section v-else-if="imageType === 'icon'" avatar>
            <q-avatar>
              <m-icons-type :type="icon" inverted />
            </q-avatar>
          </q-item-section>
        </template>
        <q-item-section>
          <q-item-label>
            {{ title }}
          </q-item-label>
          <q-item-label caption class="subtitle">
            {{ formattedSubtitle }}
          </q-item-label>
          <q-item-label v-if="email" caption>
            {{ email }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-card-section>
    <q-separator />
    <q-card-actions align="right">
      <template v-if="showActions">
        <q-btn
          color="primary"
          class="btn-view"
          tag="a"
          flat
          @click="goto('booking')"
        >
          {{ $tc('booking.booking') }}
        </q-btn>
        <q-btn
          color="primary"
          class="btn-view"
          flat
          tag="a"
          @click="goto('amend')"
        >
          {{ $t('amend') }}
        </q-btn>
        <q-btn
          color="primary"
          class="btn-view"
          flat
          tag="a"
          @click="goto('cancellation')"
        >
          {{ $t('cancellation') }}
        </q-btn>
      </template>
      <q-btn
        color="primary"
        class="btn-view"
        flat
        tag="a"
        @click="viewing"
      >
        {{ $t('view') }}
      </q-btn>
    </q-card-actions>
  </q-card>
</template>

<script>
import travelContents from 'mixins/travelContents'
import { MIconsType } from 'components/'

export default {
  components: { MIconsType },
  mixins: [travelContents],
  props: {
    close: Function,
    info: Object,
    view: {
      type: Function
    },
    user: Object,
    title: {
      type: String,
      default: 'Justin'
    },
    subTitle: {
      type: [String, Object],
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: false
    },
    reference: {
      type: String,
      default: ''
    }
  },
  computed: {
    // Checks if subtitle is an organisation object, also some users aren't part of an organisation
    formattedSubtitle () {
      if (this.subTitle !== null) {
        if (typeof this.subTitle === 'object') {
          return this.subTitle.name
        } else {
          return this.subTitle
        }
      }
      return ''
    },
    imageType () {
      if (this.icon.includes('http')) {
        return 'avatar'
      } else if (this.icon.length > 1) {
        return 'icon'
      } else {
        return false
      }
    }
  },
  methods: {
    viewing () {
      this.$emit('view', this.view)
      this.close()
    },
    goto (type) {
      this.$router.push({
        name: `new-request-${type}`,
        params: {
          requester: this.user
        }
      })
      this.close()
    }
  }
}
</script>

<style lang="stylus" scoped>
.title
  font-size: 16px;
  color: rgb(66, 66, 66)

.subtitle
  font-size: 14px

.organisation
  font-size: 12.8px

.subtitle, .organisation
  color: rgb(119, 119, 119)
.subtitle
  text-transform: capitalize

span
  display: block
</style>
